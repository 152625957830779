import { createTheme } from "@chainsafe/common-theme"
import { CsMPColors, UI_CONSTANTS } from "./Constants"

export const theme = createTheme<CsMPColors>({
  globalStyling: {
    ":root": {
      "--csmp-primary": "#B4FF68",
      "--csmp-primary-hover": "#99E54D",
      "--csmp-primary-active": "#588E23",

      "--gray1": "#141414",
      "--gray2": "#1D1D1D",
      "--gray3": "#262626",
      "--gray4": "#303030",
      "--gray5": "#434343",
      "--gray6": "#5A5A5A",
      "--gray7": "#7D7D7D",
      "--gray8": "#ACACAC",
      "--gray9": "#DBDBDB",
      "--gray10": "#FFFFFF"
    },
    a: {
      textDecoration: "none"
    }
  },
  themeConfig: {
    palette: {
      primary: {
        main: "var(--csmp-primary)",
        hover: "var(--csmp-primary-hover)",
        active: "var(--csmp-primary-active)"
      }
    },
    typography: {
      h1: {
        fontSize: 60,
        lineHeight: "70px",
        fontWeight: 400,
        letterSpacing: "-0.02em"
      },
      h2: {
        fontSize: 50,
        lineHeight: "60px",
        fontWeight: 400,
        letterSpacing: "-0.01em"
      },
      h3: {
        fontSize: 30,
        lineHeight: "34.8px",
        fontWeight: 400
      },
      h4: {
        fontSize: 25,
        lineHeight: "30px"
      },
      h5: {
        fontSize: 20,
        lineHeight: "30px"
      },
      h6: {
        fontSize: 18,
        lineHeight: "28px"
      },
      body1: {}
    },
    constants: {
      ...UI_CONSTANTS,
      ...({
        background: {
          main: "#121212"
        }
      } as CsMPColors)
    },
    overrides: {
      Button: {
        root: {
          borderRadius: "5px"
        },
        variants: {
          outline: {
            root: {
              color: "var(--csmp-primary)",
              borderColor: "var(--csmp-primary)"
            },
            hover: {
              color: "var(--csmp-primary-hover)",
              borderColor: "var(--csmp-primary-hover)"
            },
            active: {
              color: "var(--csmp-primary)",
              borderColor: "var(--csmp-primary-hover)"
            },
            focus: {
              color: "var(--csmp-primary)",
              borderColor: "var(--csmp-primary-hover)"
            }
          },
          secondary: {
            root: {
              color: "var(--csmp-primary)",
              borderColor: "var(--gray4)",
              backgroundColor: "var(--gray4)"
            },
            hover: {
              color: "var(--csmp-primary)",
              borderColor: "var(--gray5)",
              backgroundColor: "var(--gray5)"
            },
            active: {
              color: "var(--csmp-primary)",
              borderColor: "var(--gray5)",
              backgroundColor: "var(--gray5)"
            },
            focus: {
              color: "var(--csmp-primary)",
              borderColor: "var(--gray5)",
              backgroundColor: "var(--gray5)"
            }
          }
        }
      },
      TextInput: {
        label: {
          color: "var(--gray8)"
        },
        input: {
          root: {
            backgroundColor: "var(--gray2)",
            borderColor: "var(--gray7)",
            color: "var(--gray9)",
            borderRadius: 5,
            "&:hover": {
              borderColor: "var(--csmp-primary)"
            },
            "&:active": {
              borderColor: "var(--csmp-primary)"
            },
            "&:focus": {
              borderColor: "var(--csmp-primary)"
            }
          }
        },
        root: {
          margin: 0
        }
      },
      SelectInput: {
        root: {
          color: "var(--gray9)",
          borderRadius: 5
        },
        container: {
          backgroundColor: "var(--gray1)",
          color: "var(--gray9)",
          borderColor: "var(--gray7)",
          borderRadius: 5,
          boxShadow: "none",
          "&:hover": {
            borderColor: "var(--csmp-primary)",
            boxShadow: "none"
          }
        },
        control: {
          backgroundColor: "var(--gray2)",
          borderColor: "var(--gray7)",
          color: "var(--gray9)",
          borderRadius: 5,
          boxShadow: "none",
          "&:hover": {
            borderColor: "var(--csmp-primary)",
            boxShadow: "none"
          }
        },
        menuList: {
          backgroundColor: "var(--gray2)",
          color: "var(--gray9)",
          borderRadius: 5
        },
        menu: {
          backgroundColor: "var(--gray2)",
          color: "var(--gray9)",
          borderRadius: 5
        },
        option: {
          backgroundColor: "var(--gray2)",
          color: "var(--gray9)",
          cursor: "pointer",
          borderRadius: 5,
          "&:hover": {
            backgroundColor: "var(--gray4)"
          }
        },
        dropdownIndicator: {
          backgroundColor: "var(--gray1)",
          color: "var(--gray9)"
        },
        indicatorsContainer: {
          backgroundColor: "transparent",
          "& > div:last-child": {
            backgroundColor: "transparent !important",
            "&:hover": {
              color: "var(--gray9)"
            }
          }
        },
        singleValue: {
          color: "var(--gray9)"
        },
        input: {
          color: "var(--gray9)"
        }
      },
      Modal: {
        inner: {
          root: {
            borderRadius: 5,
            backgroundColor: "var(--gray2)",
            color: "var(--gray8)"
          }
        },
        closeIcon: {
          right: {
            top: 10,
            right: 10
          },
          root: {
            "& svg": {
              stroke: "var(--gray8)"
            }
          }
        }
      }
    }
  }
})
