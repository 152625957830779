import React from "react"
import "./App.css"
// import { useLocalStorage } from "@chainsafe/browser-storage-hooks"
import { ThemeProvider } from "@chainsafe/common-theme"
import {
  // ChainsafeLogo,
  CssBaseline
  //  Router, ToastProvider, 
  // Typography
} from "@chainsafe/common-components"
// import { LanguageProvider } from "./Contexts/LanguageContext"
// import { Web3Provider } from "@chainsafe/web3-context"
import { theme } from "./Themes/Theme"
// import { MarketplaceProvider } from "./Contexts/MarketplaceContextProvider"
// import { ModerationProvider } from "./Contexts/ModerationContextProvider"
import AppWrapper from "./Components/Layouts/AppWrapper"
// import Routes from "./Components/Routes"
// import { NetworkContextProvider } from "./Contexts/NetworkContextProvider"
// import { StorageApiProvider } from "./Contexts/StorageApiContext"
// import { BLOCKNATIVE_ID, config, INFURA_PROJECT_ID, STORAGE_API_URL } from "./config"
// import { OnboardConfig } from "@chainsafe/web3-context/dist/context/Web3Context"
// import { getNetworkName } from "./Utils/networkHelpers"
// import { CollectionProvider } from "./Contexts/CollectionsContext"
import { PlausibleContextProvider } from "./Contexts/PlausibleContext"

// const availableLanguages = [
//   { id: "en", label: "English" }
// ]

// const onboardConfig: OnboardConfig = {
//   dappId: BLOCKNATIVE_ID || "",
//   walletSelect: {
//     wallets: [
//       // { walletName: "coinbase" },
//       // {
//       //   walletName: "trust",
//       //   rpcUrl:
//       //     `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID}`
//       // },
//       { walletName: "metamask", preferred: true },
//       // { walletName: "authereum" },
//       // { walletName: "opera" },
//       // { walletName: "operaTouch" },
//       // { walletName: "torus" },
//       // { walletName: "status" },
//       {
//         walletName: "walletConnect",
//         infuraKey: INFURA_PROJECT_ID,
//         preferred: true
//       },
//       { walletName: "detectedwallet" }
//     ]
//   }
// }


const App = () => {
  // const { canUseLocalStorage } = useLocalStorage()
  return (
    <ThemeProvider theme={theme}>
      <PlausibleContextProvider>
        <CssBaseline />
        {/* <LanguageProvider availableLanguages={availableLanguages}> */}
        {/* <ToastProvider
            autoDismiss
            defaultPosition="bottomRight"
          >
            <Web3Provider
              onboardConfig={onboardConfig}
              networkIds={Object.keys(config).map(chainId => Number(chainId))}
              checkNetwork={false}
              cacheWalletSelection={canUseLocalStorage}
              additionalChainParams={Object.keys(config).map(chainId => ({
                chainId: Number(chainId),
                chainName: getNetworkName(Number(chainId)).chain,
                ...config[Number(chainId)].addChainParams
              }))}
            >
              <NetworkContextProvider>
                <StorageApiProvider
                  apiUrl={STORAGE_API_URL}
                  withLocalStorage={true}
                >
                  <MarketplaceProvider>
                    <CollectionProvider>
                      <ModerationProvider>
                        <Router> */}
        <AppWrapper />
        {/* <Routes /> */}
        {/* </Router>
                      </ModerationProvider>
                    </CollectionProvider >
                  </MarketplaceProvider>
                </StorageApiProvider>
              </NetworkContextProvider>
            </Web3Provider >
          </ToastProvider > */}
        {/* </LanguageProvider > */}
      </PlausibleContextProvider >
    </ThemeProvider >
  )
}

export default App
