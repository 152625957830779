import { IConstants } from "@chainsafe/common-theme"

interface ICustomConstants {
  standardPagePadding: string
  headerHeight: number
}
export const UI_CONSTANTS: ICustomConstants = {
  standardPagePadding: "24px 40px",
  headerHeight: 80
}

export interface CsMPColors extends IConstants, ICustomConstants {
  linkBlue: string
  inputBorder: string
  background: {
    main: string
  }
}
