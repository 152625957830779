import React, { useMemo } from "react"
import Plausible, { EventOptions } from "plausible-tracker"
import { PlausibleInitOptions, PlausibleOptions } from "plausible-tracker/build/main/lib/tracker"

type PlausibleContextProps = {
  plausibleInitOptions?: PlausibleInitOptions
  children: React.ReactNode
}

type PlausibleContextType = {
  trackPageview: (eventData?: PlausibleOptions, options?: EventOptions) => void
  trackEvent: (eventName: string, options?: EventOptions, eventData?: PlausibleOptions) => void
}

const PlausibleContext = React.createContext<PlausibleContextType | undefined>(undefined)

const PlausibleContextProvider = ({ plausibleInitOptions = {
  domain: `${window.location.hostname}`,
  trackLocalhost: false
}, children }: PlausibleContextProps) => {
  const { enableAutoPageviews, trackPageview, trackEvent } = useMemo(
    () => Plausible(plausibleInitOptions),
    [plausibleInitOptions]
  )

  enableAutoPageviews()
  return (
    <PlausibleContext.Provider
      value={{
        trackPageview,
        trackEvent
      }}
    >
      {children}
    </PlausibleContext.Provider>
  )
}

const usePlausible = () => {
  const context = React.useContext(PlausibleContext)
  if (context === undefined) {
    throw new Error("useProject must be used within a ProjectProvider")
  }
  return context
}

export { PlausibleContextProvider, usePlausible }
