import { createStyles, makeStyles } from "@chainsafe/common-theme"
import React from "react"
import clsx from "clsx"
import { ChainsafeLogo, CssBaseline, Typography } from "@chainsafe/common-components"
// import AppHeader from "./AppHeader"
// import AppFooter from "./AppFooter"


const useStyles = makeStyles(
  () => {
    return createStyles({
      root: {
        position: "relative"
      },
      header: {
        position: "absolute",
        width: "100%",
        top: 0,
        left: 0
      },
      bodyWrapper: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
      },
      logo: {
        width: "40px",
        marginBottom: "30px"
      },
      content: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        position: "relative"
      },
      child: {
        paddingTop: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
      },
      bodyBottom: {
        width: "100%",
        height: "260px"
      },
      gradient: {
        position: "relative",
        top: "50%",
        height: "100%",
        opacity: 0.3,
        background: "radial-gradient(50% 50% at 50% 50%, #B4FF68 0%, rgba(180, 255, 104, 0) 100%)",
        zIndex: -1
      }
    })
  }
)

const AppWrapper: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <AppHeader className={classes.header} /> */}

      <article
        className={clsx(classes.bodyWrapper)}
      >
        <section
          className={clsx(classes.content)}
        >
          <div className={classes.child}>
            <ChainsafeLogo className={classes.logo} />
            <Typography variant="h1"
              component="h1">
              New landing page
            </Typography>
            <br />
            <Typography variant="h1"
              component="h1">
              coming soon !
            </Typography>
          </div>

        </section>
        <section className={classes.bodyBottom}>
          <section className={classes.gradient} />
        </section>
        {/* <AppFooter /> */}
      </article>
    </div>
  )
}

export default AppWrapper
